import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import Img from "gatsby-image/withIEPolyfill"

import device from "../device"
import arrowNext from "../../images/services/arrow icon.svg"
import arrowPrev from "../../images/services/arrow icon 2.svg"

const Wrapper = styled.div`
  margin: 2rem auto;
  max-width: 1750px;
  width: 100%;
  ${device.small`margin: 1rem 0 1rem 7%; width: var(--spread);`}
  .slick-slide {
    margin: 1rem 0;
    padding: 1px 1px 5rem 1px;
    ${device.small`padding-bottom: 4rem;`}
  }
  .slick-center {
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--green);
    border-radius: 20px;
    ${device.small`box-shadow: none; border: none;`}
  }
  .slick-arrow {
    z-index: 100;
    position: absolute;
    bottom: 1rem;
    transform: translate(-50%, -50%);
    ${device.small`bottom: 0;`}
    img {
      width: 45px;
      display: block;
      ${device.small`width: 40px;`}
    }
    &.slick-next {
      left: calc(50% + 35px);
      ${device.small`left: 75px;`}
    }
    &.slick-prev {
      left: calc(50% - 35px);
      transform: rotate(180deg) translate(50%, 50%);
      ${device.small`left: 25px;`}
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
`

const Slide = styled.div`
  transition: all 0.3s;
  border: 1px solid transparent;
  .slide-inner {
    max-height: 500px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    border: 5px solid var(--white);
    border-bottom: 0;
    .image {
      z-index: -1;
    }
    .text {
      transition: all 0.3s;
      width: 50%;
      padding: 2rem;
      position: absolute;
      bottom: 0;
      h3 {
        color: var(--white);
      }
      span {
        letter-spacing: 2px;
        text-transform: uppercase;
        color: var(--green);
        font-size: 0.7rem;
      }
    }
    &:hover {
      .text {
        opacity: 0;
      }
      .overlay {
        top: 0;
      }
    }
    .overlay {
      transition: all 0.3s ease-out;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 100%;
      color: var(--white);
      background: rgb(178, 178, 129, 0.9);
      .content {
        position: relative;
        padding: 2rem;
        p {
          margin: 1rem 0;
          font-size: 0.85rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 12;
          -webkit-box-orient: vertical;
        }
        .learn-more {
          display: block;
          margin: 4rem 0;
          font-size: 0.7rem;
          letter-spacing: 1px;
          color: var(--gold);
        }
      }
    }
  }
`

const ArrowNext = props => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={arrowNext} alt="Slider Button" />
    </div>
  )
}
const ArrowPrev = props => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src={arrowPrev} alt="Slider Button" />
    </div>
  )
}

const SliderContent = ({ post }) => (
  <div className="slide-inner">
    {post.featured_media.localFile && (
      <Img
        className="image"
        fluid={post.featured_media.localFile.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={post.featured_media.alt_text}
      />
    )}
    <div className="text">
      <h3>{post.title}</h3>
      <span>More Info</span>
    </div>

    <div className="overlay">
      <div className="content">
        <h3>{post.title}</h3>
        <p>{post.acf.text}</p>
        <a href={post.acf.link} className="learn-more">
          Learn More
        </a>
      </div>
    </div>
  </div>
)

const ServiceSlider = ({ posts }) => {
  const [loaded, setLoaded] = useState(false)

  const settings = {
    slidesToScroll: 1,
    slidesToShow: 5,
    infinite: posts.length <= 5 ? false : true,
    centerMode: posts.length <= 5 ? false : true,
    centerPadding: 0,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4.5,
          infinite: posts.length <= 4 ? false : true,
          centerMode: posts.length <= 4 ? false : true,
          centerPadding: "-6%",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: posts.length <= 3 ? false : true,
          centerMode: posts.length <= 3 ? false : true,
          centerPadding: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.25,
          infinite: false,
          centerMode: false,
          centerPadding: 0,
          shouldPopulate: false,
        },
      },
    ],
  }

  useEffect(() => {
    setLoaded(true)
    return () => setLoaded(false)
  }, [posts])

  return (
    <Wrapper>
      {loaded && (
        <Slider {...settings}>
          {posts.map((post, i) => (
            <Slide key={i}>
              <SliderContent post={post} />
            </Slide>
          ))}
        </Slider>
      )}
    </Wrapper>
  )
}

export default ServiceSlider
